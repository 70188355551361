import React from "react"
import PropTypes from "prop-types"
import { Header } from "semantic-ui-react"

import renderAst from "./render-markdown"

const HeaderParagraph = ({ title, htmlAst, headerAs }) => (
  <>
    <Header as={headerAs} textAlign="center">
      {title}
    </Header>
    {renderAst(htmlAst)}
  </>
)

HeaderParagraph.propTypes = {
  title: PropTypes.string.isRequired,
  htmlAst: PropTypes.object.isRequired,
  headerAs: PropTypes.string.isRequired,
}

export default HeaderParagraph
